.formInputSimpleContainer {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.formInputSimpleLabel {
  margin-bottom: 1vh;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.1vw;
  line-height: 1.1vw;
  color: #333;
}

.formInputSimple {
  color: #fff;
  background-color: #131414;
  border-bottom: 1px solid #fff;
  border-right: none;
  border-top: none;
  border-left: none;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 1.5vw;
  margin-top: 0;
  margin-bottom: 2vh;
  height: 3vw;
  width: 100%;
  padding-left: 1vw;
}

.formInputSimple::placeholder {
  color: rgba(255, 255, 255, 1);
}

.formInputSimple:focus {
  outline: none;
}

@media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    font-size: 2vw;
    line-height: 2vw;
  }
  
  .formInputSimple {
    font-size: 2.5vw;
    line-height: 2.5vw;
    height: 5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
  
  .formInputSimple {
    font-size: 5vw;
    line-height: 5vw;
    height: 10vw;
  }
}