.contactContainer {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.contactImgWrapper {
  width: 50vw;
  height: 100%;
  overflow: hidden;
}

.contactImg {
  width: 50vw;
  object-fit: cover;
  filter: brightness(0.7);
}

.contactInfoContainer {
  width: 50vw;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 5%;
}

.contactInfoTitle {
  font-family: 'titleFont';
  font-size: 2.5vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 2vh;
  margin-right: 6vw;
}

.contactInfoText {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 2vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 8vh;
  margin-right: 6vw;
}

.contactInfoLink {
  position: relative;
  margin-bottom: 2vh;
}

.contactInfoUnderline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 0;
  border-bottom: 1px solid #999;
  transition: width 0.5s ease-in-out;
}

.contactInfoLink:hover .contactInfoUnderline {
  width: 100%;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .contactInfoContainer {
    width: 50vw;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 10%;
  }
  
  .contactInfoTitle {
    font-family: 'titleFont';
    font-size: 4.5vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 2vh;
    margin-right: 6vw;
  }
  
  .contactInfoText {
    font-family: 'Helvetica Neue';
    font-weight: 300;
    font-size: 2.5vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 5vh;
    margin-right: 6vw;
  }
  
  .contactInfoLink {
    position: relative;
    margin-bottom: 2vh;
  }
  
  .contactInfoUnderline {
    display: none;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .contactImgWrapper {
    display: none;
  }
  
  .contactInfoContainer {
    width: 100vw;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 10%;
  }
  
  .contactInfoTitle {
    font-family: 'titleFont';
    font-size: 8vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 2vh;
    margin-right: 8vw;
  }
  
  .contactInfoText {
    font-family: 'Helvetica Neue';
    font-weight: 300;
    font-size: 5.5vw;
    margin-right: 8vw;
  }

  .contactInfoLink {
    margin-bottom: 3vh;
  }
}