.paymentFailTitle {
  font-family: 'titleFont';
  font-size: 3.5vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 3vh;
}

.paymentFailureImg {
  width: 25vw;
  height: auto;
  object-fit: cover;
  margin-bottom: 8vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .paymentFailTitle {
    font-size: 6vw;
    margin-bottom: 3vh;
  }
  
  .paymentFailureImg {
    width: 35vw;
    margin-bottom: 5vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .paymentFailTitle {
    font-size: 8vw;
    margin-bottom: 3vh;
  }
  
  .paymentFailureImg {
    width: 60vw;
    margin-bottom: 5vh;
  }
}

