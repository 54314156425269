.collectionContainer {
  position: absolute;
  left: 0;
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.collectionTransitionImg {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  filter: brightness(0.5);
}