.collectionHeader {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.collectionTitleContainer {
  position: absolute;
  bottom: 15vh;
  width: 88vw;
  left: 6vw;
  height: 10vh;
  overflow: hidden;
  z-index: 200;
}

.collectionTitle {
  font-family: "titleFont";
  color: rgba(255,255,255,1);
  font-size: 5.5vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  position: absolute;
  bottom: 0vh;
  left: 0;
}

.categoriesSelectorOuterContainer {
  width: 100vw;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.categoriesSelectorText {
  font-family: 'titleFont';
  font-size: 5vw;
  color: #333;
  cursor: pointer;
}

.categoriesSelectorText:hover .categoriesSelectorInner {
  height: 95vh;
  opacity: 1;
}

.categoriesSelectorInner {
  width: 100%;
  height: 0vh;
  background-color: #fff;
  overflow-y: hidden;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  display: block;
  opacity: 0.1;
  transition: all 0.5s ease-in-out;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.categoriesSelectorInner::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.categoriesSelectorInner {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.categoriesSelectorInnerContainer {
  width: 100%;
  height: 95vh;
  display: inline-block;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-left: 20vw;
}

.categoryText {
  font-family: 'titleFont';
  font-size: 1.5vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10vw;
  cursor: pointer;
  position: relative;
  margin-bottom: 2.5vh;
  width: auto;
}

.categoryTextUnderline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0;
  width: 0;
  border-bottom: 1px solid rgba(51,51,51,0.3);
  transition: width 0.5s ease-out;
}

.categoryText:hover .categoryTextUnderline {
  width: 100%;
}

.newInLoadingContainer {
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 2vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.productsContainer {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10vh;
}

.productGroupContainer {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.firstMainContainer {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30vh;
  cursor: pointer;
}

.firstMainImgWrapper {
  width: 40vw;
  height: 110vh;
  overflow: hidden;
  position: relative;
}

.preOrderBanner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding-top: 1vh;
  padding-bottom: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.4vw;
  color: #fff;
  background-color: #131414;
}

.firstMainImg {
  width: 40vw;
  height: 110vh;
  object-fit: cover;
  transition: transform 0.5s ease-in-out 0.3s;
}

.overlayMainProduct {
  height: 110vh;
  cursor: pointer;
}

.firstMainInfo {
  width: 30vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 3vw;
  margin-bottom: 20vh;
}

.firstMainInfoTitle {
  font-family: 'titleFont';
  font-size: 3vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 4vh;
}

.firstMainInfoDesc {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1vw;
  line-height: 2vw;
  text-align: left;
  color: #333;
  margin-top: 0;
  margin-bottom: 4vh;
  width: 20vw;
}

.firstMainInfoPrice {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.6vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.doubleContainer {
  width: 73vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30vh;
}

.doubleContainerInner {
  width: 45%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}

.doubleContainerImgWrapper {
  width: 100%;
  height: 90vh;
  overflow: hidden;
  margin-bottom: 2.5vh;
  position: relative;
}

.doubleContainerImg {
  width: 100%;
  height: 90vh;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}

.overlayDoubleProduct {
  height: 97vh;
  cursor: pointer;
}

.doubleContainerName {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.6vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 1vh;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.doubleContainerPrice {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.1vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.secondMainInfo {
  width: 30vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: 3vw;
  z-index: 100;
  margin-bottom: 20vh;
}

.newInMainOverlay {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.newInNextContainer {
  background-color: #131414 !important;
}

.newInNextTitle {
  color: #fff !important;
}

.newInNextLine {
  border-bottom: 0.1px solid rgba(255, 255, 255, 0.5) !important;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .collectionTitleContainer {
    bottom: 12vh;
  }
  
  .collectionTitle {
    font-size: 8vw;
  }
  
  .categoriesSelectorOuterContainer {
    height: 90vh;
  }
  
  .categoriesSelectorText {
    font-size: 6vw;
  }
  
  .categoriesSelectorText:hover .categoriesSelectorInner {
    height: 90vh;
    opacity: 1;
  }
  
  .categoriesSelectorInnerContainer {
    height: 90vh;
  }
  
  .categoryText {
    font-size: 2vw;
    margin-bottom: 3vh;
  }
  
  .newInLoadingContainer {
    height: 40vh;
    font-size: 3vw;
  }
  
  .firstMainContainer {
    margin-bottom: 20vh;
  }
  
  .firstMainImgWrapper {
    width: 45vw;
    height: 55vh;
  }

  .preOrderBanner {
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 2vw;
  }
  
  .firstMainImg {
    width: 45vw;
    height: 55vh;
  }
  
  .overlayMainProduct {
    height: 55vh;
  }
  
  .firstMainInfo {
    width: 40vw;
    margin-left: 4vw;
    margin-bottom: 15vh;
  }
  
  .firstMainInfoTitle {
    font-size: 4vw;
    margin-bottom: 3vh;
  }
  
  .firstMainInfoDesc {
    font-size: 1.7vw;
    line-height: 3.4vw;
    margin-bottom: 3vh;
    width: 35vw;
  }
  
  .firstMainInfoPrice {
    font-size: 2.3vw;
  }
  
  .doubleContainer {
    width: 89vw;
    margin-bottom: 20vh;
  }
  
  .doubleContainerInner {
    width: 45%;
  }
  
  .doubleContainerImgWrapper {
    height: 60vh;
    margin-bottom: 1.5vh;
  }
  
  .doubleContainerImg {
    height: 60vh;
  }
  
  .overlayDoubleProduct {
    height: 70vh;
  }
  
  .doubleContainerName {
    font-size: 2.6vw;
    margin-bottom: 0.5vh;
  }
  
  .doubleContainerPrice {
    font-size: 1.8vw;
  }
  
  .secondMainInfo {
    width: 40vw;
    margin-right: 4vw;
    margin-bottom: 15vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .collectionTitleContainer {
    bottom: 10vh;
  }
  
  .collectionTitle {
    font-size: 10vw;
  }
  
  .categoriesSelectorOuterContainer {
    height: 85vh;
  }
  
  .categoriesSelectorText {
    font-size: 8vw;
  }
  
  .categoryText {
    font-size: 3.5vw;
    margin-bottom: 3vh;
  }
  
  .newInLoadingContainer {
    height: 50vh;
    font-size: 4.5vw;
    text-align: center;
  }
  
  .firstMainContainer {
    margin-bottom: 15vh;
  }
  
  .firstMainImgWrapper {
    width: 47vw;
    height: 50vh;
  }

  .preOrderBanner {
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 3vw;
  }
  
  .firstMainImg {
    width: 47vw;
    height: 50vh;
  }
  
  .overlayMainProduct {
    height: 50vh;
  }
  
  .firstMainInfo {
    width: 44vw;
    margin-left: 4vw;
    margin-bottom: 5vh;
  }
  
  .firstMainInfoTitle {
    font-size: 5.7vw;
    margin-bottom: 2.5vh;
  }
  
  .firstMainInfoDesc {
    font-size: 3vw;
    line-height: 6vw;
    margin-bottom: 2.5vh;
    width: 40vw;
  }
  
  .firstMainInfoPrice {
    font-size: 3.8vw;
  }
  
  .doubleContainer {
    width: 95vw;
    margin-bottom: 10vh;
  }
  
  .doubleContainerInner {
    width: 47.5%;
  }
  
  .doubleContainerImgWrapper {
    height: 45vh;
    margin-bottom: 1.5vh;
  }
  
  .doubleContainerImg {
    height: 45vh;
  }
  
  .overlayDoubleProduct {
    height: 50vh;
  }
  
  .doubleContainerName {
    font-size: 3.5vw;
    margin-bottom: 0.5vh;
  }
  
  .doubleContainerPrice {
    font-size: 3vw;
  }
  
  .secondMainInfo {
    width: 44vw;
    margin-right: 4vw;
    margin-bottom: 5vh;
  }
}