.checkoutContainerInner {
  width: 90%;
  height: 94%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkoutStatusOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 50vw;
  padding-left: 25vw;
  padding-right: 25vw;
  z-index: 1000000000008;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  color: #fff;
  font-size: 1.8vw;
  background-color: rgba(19, 20, 20, 0.9);
}

.checkoutContainerFirstLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3vh;
}

.checkoutContainerPrice {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.5vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
}

.checkoutContainerCloseButton {
  display: flex;
  width: 1.5vw;
  height: auto;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease-out;
}

.checkoutContainerCloseButton:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.checkoutContainerNav {
  border-top: 1px solid rgba(255,255,255,0.5);
  border-bottom: 1px solid rgba(255,255,255,0.5);
  width: 100%;
  height: 7vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkoutContainerNavLink {
  flex: 1;
  height: calc(100% - 3vh);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
  border-right: 1px solid rgba(255,255,255,0.5);
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 0.8vw;
  cursor: pointer;
  transition: color 0.3s ease-out;
}

.checkoutMainContainer {
  width: 100%;
  flex: 1;
  max-height: 66%;
}

.checkoutContainerLastLine {
  width: 100%;
  padding-top: 5vh;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  border-top: 1px solid rgba(255,255,255,0.5);
}

.checkoutContainerMainButton {
  border: none;
  width: 13vw;
  height: 7vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 0.8vw;
  line-height: 0.8vw;
  color: #333;
  background-color: #fff;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  margin-left: 1.5vw;
}

.checkoutContainerMainButton:hover {
  opacity: 0.8;
}

.checkoutContainerMainButton:disabled {
  opacity: 0.3;
}

.checkoutContainerMainButton:focus {
  outline: none;
}

.checkoutCartContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: block;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.checkoutCartContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.checkoutCartContainer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.checkoutCartLine {
  width: 100%;
  height: auto;
  padding-top: 3vh;
  padding-bottom: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkoutCartRemoveButton {
  width: 2.5vw;
  height: auto;
  object-fit: cover;
  margin-right: 3vw;
  margin-left: 0.3vw;
  cursor: pointer;
  transition: transform 0.3s ease-out;
}

.checkoutCartRemoveButton:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.checkoutCartImg {
  width: 8%;
  height: 18vh;
  object-fit: cover;
  margin-right: 4vw;
}

.checkoutLineInfoContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkoutLineInfoMain {
  width: 35vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2vw;
}

.checkoutLineTitle {
  width: 100%;
  font-family: 'titleFont';
  font-size: 2.5vw;
  color: #fff;
  margin-top: -1vh;
  margin-bottom: 2vh;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkoutLineSub {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.5vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkoutLineInfoSec {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkoutLineInfoSecAux {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.checkoutLineInfoSecSize {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.3vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 2vh;
}

.checkoutLineInfoSecQuantity {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.3vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.checkoutLineInfoSecQuantityContainer {
  width: auto;
  height: 4.2vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e1e1e1;
  margin-left: 1vw;
}

.checkoutLineInfoSecQuantityIcon {
  color: #fff;
  height: 3vh;
  width: 3vh;
  cursor: pointer;
}

.checkoutLineInfoSecQuantityText {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.3vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1.3vw;
  margin-right: 1.3vw;
}

.checkoutLineInfoSecPrice {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.7vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  text-align: right;
}

.checkoutMobileTabIcon {
  display: none;
}

.checkoutAccountContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.checkoutSignInContainer {
  width: 34%;
  height: 100%;
  margin-right: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.checkoutAccountMessageContainer {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.checkoutAccountMessage {
  width: 90%;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1vw;
  line-height: 2vw;
  color: #fff;
  text-align: justify;
  margin-top: 3vh;
}

.checkoutSignInTitle {
  width: 100%;
  height: auto;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.8vw;
  line-height: 2.4vw;
  color: #fff;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.checkoutSignInForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkoutAccountSignOutContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.checkoutAccountSignOutMessage {
  width: 100%;
  height: auto;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 2.2vw;
  color: #e1e1e1;
  margin-top: 3vh;
  margin-bottom: 1.5vh;
}

.checkoutAccountSignOutEmail {
  width: 100%;
  height: auto;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 2.8vw;
  line-height: 3.6vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 3vh;
}

.checkoutMainInfoContainer {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.smallProductSelect {
  color: #fff;
  background-color: #131414;
  border-bottom: 1px solid #fff;
  border-right: none;
  border-top: none;
  border-left: none;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 1.2vw;
  margin-top: 0;
  margin-bottom: 2vh;
  height: 2vw;
  width: 100%;
  padding-left: 0.5vw;
}

.smallProductSelect:focus {
  outline: none;
}

.smallProductSelectOption {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1vw;
  color: #fff;
}

.checkoutConfirmationContainer {
  width: 100%;
  height: calc(100% - 6vh);
  padding-top: 3vh;
  padding-bottom: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkoutConfirmationTitleContainer {
  width: 22%;
  padding-right: 3%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.checkoutDeliveryLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.checkoutDeliveryCheckbox {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid rgba(225,225,225,0.5);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1vw;
}

.checkoutDeliveryCheck {
  width: 17px;
  height: 17px;
  color: #e1e1e1;
  transition: opacity 0.3s ease-out;
}

.checkoutDeliveryInfoContainer {
  width: 100%;
  height: auto;
  text-align: left;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1vw;
  line-height: 1.6vw;
  color: #fff;
}

.checkoutDeliveryTotal {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 2vw;
  color: #fff;
  margin-top: 5vh;
  margin-bottom: 0;
}

.checkoutConfirmationWarning {
  width: 90%;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1vw;
  line-height: 2vw;
  color: #fff;
  margin-top: -1vh;
  margin-bottom: 0;
}

.checkoutConfirmationProductsOuter {
  width: 35%;
  height: 100%;
  display: block;
  overflow: hidden;
  position: relative;
}

.checkoutConfirmationProducts {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-right: 1vw;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.checkoutConfirmationProducts::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.checkoutConfirmationProducts {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.checkoutConfirmationAddress {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.3vw;
  line-height: 2.2vw;
  color: #fff;
  margin-top: 2vh;
  margin-bottom: 0;
  align-self: flex-end;
}

.checkoutConfirmationPrice {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.8vw;
  line-height: 2.7vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 2vh;
  text-align: right;
}

.checkoutConfirmationProductsLine {
  width: 100%;
  margin-top: 2.1vh;
  margin-bottom: 2.1vh;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkoutConfirmationProductsQuantity {
  width: auto;
  height: auto;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  color: #fff;
  font-size: 1.2vw;
  line-height: 1.2vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 3vw;
}

.checkoutConfirmationProductInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.checkoutConfirmationProductTitle {
  width: 100%;
  height: auto;
  margin-bottom: 1.5vh;
  font-family: 'titleFont';
  font-size: 1.5vw;
  color: #fff;
  margin-top: -0.5vh;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkoutConfirmationProducExtra {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkoutConfirmationProductSub {
  flex: 1;
  margin-bottom: 0;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1vw;
  color: #fff;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkoutConfirmationProductSize {
  width: auto;
  height: auto;
  margin-bottom: 0;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1vw;
  color: #fff;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .checkoutContainerInner {
    width: 94%;
    height: 90%;
    padding: 5% 3% 5% 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .checkoutStatusOverlay {
    width: 70vw;
    padding-left: 15vw;
    padding-right: 15vw;
    font-size: 3vw;
    line-height: 4.5vw;
  }
  
  .checkoutContainerPrice {
    font-size: 3vw;
  }
  
  .checkoutContainerCloseButton {
    width: 3vw;
  }
  
  .checkoutContainerCloseButton:hover {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
  }
  
  .checkoutContainerNav {
    border-top: 1px solid rgba(255,255,255,0.5);
    border-bottom: 1px solid rgba(255,255,255,0.5);
    width: 100%;
    height: 6vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .checkoutContainerNavLink {
    font-size: 1.4vw;
  }
  
  .checkoutMainContainer {
    width: 100%;
    max-height: 70%;
  }
  
  .checkoutContainerLastLine {
    padding-top: 3vh;
  }
  
  .checkoutContainerMainButton {
    width: 27vw;
    height: 5vh;
    font-size: 1.4vw;
    line-height: 1.4vw;
  }
  
  .checkoutContainerMainButton:hover {
    opacity: 1;
  }
  
  .checkoutCartLine {
    padding-top: 3vh;
    padding-bottom: 3vh;
  }
  
  .checkoutCartRemoveButton {
    width: 4vw;
    margin-right: 4vw;
    margin-left: 0;
  }

  .checkoutCartRemoveButton:hover {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
  }
  
  .checkoutCartImg {
    width: 20%;
    height: 20vh;
  }
  
  .checkoutLineInfoContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .checkoutLineInfoMain {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
  
  .checkoutLineTitle {
    font-size: 4.2vw;
    margin-top: -1vh;
    margin-bottom: 1.7vh;
  }
  
  .checkoutLineSub {
    font-size: 2.5vw;
  }
  
  .checkoutLineInfoSec {
    margin-top: 4vh;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .checkoutLineInfoSecSize {
    font-size: 2.3vw;
    margin-bottom: 1.5vh;
  }
  
  .checkoutLineInfoSecQuantity {
    font-size: 2.3vw;
  }
  
  .checkoutLineInfoSecQuantityContainer {
    width: auto;
    height: 3.5vh;
    border: 1px solid rgba(255,255,255,0.5);
    margin-left: 3vw;
  }
  
  .checkoutLineInfoSecQuantityIcon {
    height: 2.5vh;
    width: 2.5vh;
  }
  
  .checkoutLineInfoSecQuantityText {
    font-size: 2.5vw;
    margin-left: 2vw;
    margin-right: 2vw;
  }
  
  .checkoutLineInfoSecPrice {
    font-size: 2.8vw;
    margin-bottom: 0.7vh;
  }
  
  .checkoutAccountMessageContainer {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .checkoutAccountMessage {
    width: 95%;
    font-size: 1.6vw;
    line-height: 3.2vw;
    margin-top: 3vh;
  }
  
  .checkoutSignInTitle {
    font-size: 2.8vw;
    line-height: 3.8vw;
    margin-bottom: 2vh;
  }
  
  .checkoutAccountSignOutContainer {
    width: 95%;
    margin: auto;
  }
  
  .checkoutAccountSignOutMessage {
    font-size: 2.8vw;
    line-height: 3.5vw;
    margin-bottom: 1vh;
  }
  
  .checkoutAccountSignOutEmail {
    font-size: 4vw;
    line-height: 5.5vw;
    margin-bottom: 5vh;
  }
  
  .smallProductSelect {
    font-size: 2.2vw;
    line-height: 2.2vw;
    height: 3.7vw;
  }
  
  .smallProductSelectOption {
    font-family: 'Helvetica Neue';
    font-weight: 700;
    font-size: 1vw;
    color: #fff;
  }
  
  .checkoutConfirmationContainer {
    width: 100%;
    height: calc(100% - 4vh);
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  
  .checkoutConfirmationWarning {
    width: 95%;
    font-size: 1.7vw;
    line-height: 3.4vw;
  }
  
  .checkoutDeliveryCheckbox {
    margin-right: 2vw;
  }
  
  .checkoutDeliveryInfoContainer {
    width: 80%;
    height: auto;
    text-align: left;
    font-family: 'Helvetica Neue';
    font-weight: 300;
    font-size: 1.8vw;
    line-height: 2.8vw;
    color: #fff;
  }
  
  .checkoutDeliveryTotal {
    font-size: 2vw;
    line-height: 3vw;
  }
  
  .checkoutConfirmationProductsOuter {
    width: 35%;
    height: 100%;
    display: block;
  }
  
  .checkoutConfirmationProducts {
    width: 100%;
    flex: 1;
    height: 100%;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  
  .checkoutConfirmationAddress {
    margin-top: 0.5vh;
    font-size: 2vw;
    line-height: 4vw;
    text-align: right;
  }
  
  .checkoutConfirmationPrice {
    font-size: 3.2vw;
    line-height: 5vw;
    margin-bottom: 2vh;
    text-align: right;
  }
  
  .checkoutConfirmationProductsLine {
    margin-top: 2.1vh;
    margin-bottom: 2.1vh;
  }
  
  .checkoutConfirmationProductsQuantity {
    font-size: 2vw;
    line-height: 2vw;
    margin-right: 3vw;
  }
  
  .checkoutConfirmationProductTitle {
    flex: 1;
    width: 25vw;
    margin-bottom: 1.5vh;
    font-size: 2.7vw;
  }
  
  .checkoutConfirmationProducExtra {
    width: 90%;
  }
  
  .checkoutConfirmationProductSub {
    font-size: 1.8vw;
  }
  
  .checkoutConfirmationProductSize {
    font-size: 1.8vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .checkoutContainerInner {
    width: 94%;
    height: 92%;
    padding: 4% 3% 4% 3%;
  }

  .checkoutStatusOverlay {
    width: 80vw;
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: 5vw;
    line-height: 8vw;
  }
  
  .checkoutContainerPrice {
    font-size: 5vw;
  }
  
  .checkoutContainerCloseButton {
    width: 5vw;
  }
  
  .checkoutContainerNav {
    height: 7vh;
  }

  .checkoutMobileTabIcon {
    display: flex;
    width: 17px;
    height: 17px;
  }
  
  .checkoutMainContainer {
    width: 100%;
    height: 70%;
  }
  
  .checkoutContainerLastLine {
    justify-content: space-between;
  }
  
  .checkoutContainerMainButton {
    width: 45vw;
    height: 6vh;
    font-size: 3vw;
    line-height: 3vw;
    margin-left: 0;
    margin-right: 0;
  }
  
  .checkoutCartLine {
    padding-top: 3vh;
    padding-bottom: 3vh;
    position: relative;
  }
  
  .checkoutCartRemoveButton {
    position: absolute;
    top: 3vh;
    right: 0;
    margin-right: 0;
    width: 3vh;
  }
  
  .checkoutCartImg {
    width: 20%;
    height: 16vh;
    margin-right: 3vw;
  }
  
  .checkoutLineInfoContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .checkoutLineInfoMain {
    align-items: flex-start;
  }
  
  .checkoutLineTitle {
    font-size: 5vw;
    margin-top: -0.5vh;
    margin-bottom: 1.5vh;
    width: 70%;
  }
  
  .checkoutLineSub {
    font-size: 3.5vw;
  }
  
  .checkoutLineInfoSec {
    margin-top: 4vh;
  }
  
  .checkoutLineInfoSecSize {
    font-size: 3.5vw;
    margin-bottom: 1vh;
  }
  
  .checkoutLineInfoSecQuantity {
    font-size: 3.5vw;
  }
  
  .checkoutLineInfoSecQuantityContainer {
    height: 3.5vh;
    margin-left: 3vw;
  }
  
  .checkoutLineInfoSecQuantityIcon {
    height: 2vh;
    width: 2vh;
  }
  
  .checkoutLineInfoSecQuantityText {
    font-size: 3vw;
    margin-left: 3vw;
    margin-right: 3vw;
  }
  
  .checkoutLineInfoSecPrice {
    font-size: 4vw;
    margin-bottom: 0.7vh;
  }

  .checkoutAccountContainer {
    width: 100%;
    height: 100%;
    display: block;
    padding-top: 3vh;
    padding-bottom: 7vh;
  }
  
  .checkoutSignInContainer {
    width: 90%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }
  
  .checkoutAccountMessageContainer {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
  }
  
  .checkoutAccountMessage {
    width: 100%;
    font-size: 4vw;
    line-height: 8vw;
    margin-top: 1vh;
  }
  
  .checkoutSignInTitle {
    font-size: 6vw;
    line-height: 8vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: auto;
    margin-right: auto;
  }

  .checkoutSignInForm {
    margin-bottom: 5vh;
  }
  
  .checkoutAccountSignOutMessage {
    font-size: 4.5vw;
    line-height: 5.2vw;
    margin-bottom: 1vh;
  }
  
  .checkoutAccountSignOutEmail {
    font-size: 5.5vw;
    line-height: 7.8vw;
    margin-bottom: 15vh;
  }
  
  .checkoutMainInfoContainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10vh;
  }
  
  .smallProductSelect {
    font-size: 4vw;
    line-height: 4vw;
    height: 8.5vw;
    padding-left: 0;
  }
  
  .smallProductSelectOption {
    font-family: 'Helvetica Neue';
    font-weight: 700;
    font-size: 3.5vw;
    color: #fff;
  }
  
  .checkoutConfirmationContainer {
    width: 100%;
    height: calc(100% - 6vh);
    padding-top: 3vh;
    padding-bottom: 3vh;
    display: block;
  }
  
  .checkoutConfirmationTitleContainer {
    width: 100%;
    padding-right: 0;
    height: auto;
    margin-bottom: 3vh;
  }
  
  .checkoutDeliveryCheckbox {
    margin-right: 3vw;
  }
  
  .checkoutDeliveryInfoContainer {
    font-size: 3.5vw;
    line-height: 6vw;
  }
  
  .checkoutDeliveryTotal {
    font-size: 4.5vw;
    line-height: 7vw;
    margin-top: 3vh;
  }
  
  .checkoutConfirmationWarning {
    width: 95%;
    font-size: 4.2vw;
    line-height: 7vw;
  }
  
  .checkoutConfirmationProductsOuter {
    width: 100%;
    height: auto;
  }
  
  .checkoutConfirmationProducts {
    width: 100%;
    height: auto;
    min-height: auto;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1vh;
    padding-bottom: 1vh;
  }
  
  .checkoutConfirmationAddress {
    font-size: 4.5vw;
    line-height: 7vw;
    text-align: left;
    margin-top: 2vh;
    margin-bottom: 4;
  }
  
  .checkoutConfirmationPrice {
    align-self: flex-end;
    font-size: 6vw;
    line-height: 9vw;
    margin-top: 3vh;
    margin-bottom: 5vh;
    text-align: right;
  }
  
  .checkoutConfirmationProductsLine {
    margin-top: 2.1vh;
    margin-bottom: 2.1vh;
  }
  
  .checkoutConfirmationProductsQuantity {
    font-size: 4vw;
    line-height: 4vw;
    margin-right: 5vw;
  }
  
  .checkoutConfirmationProductTitle {
    width: 80vw;
    margin-bottom: 2vh;
    font-family: 'titleFont';
    font-size: 6vw;
  }
  
  .checkoutConfirmationProducExtra {
    width: 90%;
  }
  
  .checkoutConfirmationProductSub {
    font-size: 4vw;
  }
  
  .checkoutConfirmationProductSize {
    font-size: 4vw;
  }
}