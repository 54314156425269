.navbar {
  position: fixed;
  top: 4vh;
  width: 95vw;
  left: 2.5vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10000000;
  transition: opacity 0.5s ease-out;
  pointer-events: none;
}

.navbar:hover {
  opacity: 1;
}

.logoContainer {
  width: 5vw;
  height: 5vw;
  border-radius: 2.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(51,51,51,0.1);
  cursor: pointer;
  transition: all 0.3s ease-out;
  color: rgba(51,51,51,1);
  z-index: 10000005;
  pointer-events: all;
}

.logoContainer:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.logo {
  height: auto;
  width: 2.5vw;
  object-fit: cover;
  cursor: pointer;
  z-index: 10000005;
  filter: brightness(0%) invert(0);
}

.navBtns {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.navBtnContainer {
  width: 5vw;
  height: 5vw;
  border-radius: 2.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(51,51,51,0.1);
  cursor: pointer;
  margin-left: 1vw;
  transition: all 0.3s ease-out;
  color: rgba(0,0,0,1);
  z-index: 10000000;
  pointer-events: all;
}

.navBtnContainer:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.shoppingGlyphContainer:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
}

.menuNavBtn {
  z-index: 10000005 !important;
}

.menuBtnText {
  font-family: 'titleFont';
  font-size: 1vw;
  line-height: 0.7vw;
  letter-spacing: 3px;
  color: inherit;
  margin-top: 0.1vh;
  margin-bottom: 0.6vh;
}

.menuBtnAlignLeft {
  margin-right: 0.5vw;
}

.menuBtnAlignRight {
  margin-left: 0.7vw;
}

.shoppingGlyph {
  color: inherit;
  width: 20px;
  height: 20px;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .navbar {
    position: fixed;
    top: 3vh;
  }
  
  .logo {
    height: auto;
    width: 4vw;
  }
  
  .navBtnContainer {
    width: 8vw;
    height: 8vw;
    border-radius: 4vw;
  }

  .logoContainer {
    width: 8vw;
    height: 8vw;
    border-radius: 4vw;
    margin-left: 0;
  }
  
  .menuBtnText {
    font-family: 'titleFont';
    font-size: 1.6vw;
    line-height: 1.2vw;
    letter-spacing: 3px;
    color: inherit;
    margin-top: 0.1vh;
    margin-bottom: 0.6vh;
    transition: color 0.5s ease-out;
  }
  
  .menuBtnAlignLeft {
    margin-right: 1vw;
  }
  
  .menuBtnAlignRight {
    margin-left: 1.2vw;
  }
  
  .shoppingGlyph {
    color: inherit;
    width: 20px;
    height: 20px;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .logo {
    height: auto;
    width: 8vw;
  }

  .navBtnContainer {
    width: 15vw;
    height: 15vw;
    border-radius: 7.5vw;
    margin-left: 3vw;
  }

  .logoContainer {
    width: 15vw;
    height: 15vw;
    border-radius: 7.5vw;
    margin-left: 0;
  }
  
  .menuBtnText {
    font-size: 3.4vw;
    line-height: 3vw;
    margin-top: 0.1vh;
    margin-bottom: 0.6vh;
  }
  
  .menuBtnAlignLeft {
    margin-right: 1vw;
  }
  
  .menuBtnAlignRight {
    margin-left: 1.5vw;
  }
  
  .shoppingGlyph {
    color: inherit;
    width: 15px;
    height: 15px;
  }
}

@media (orientation: landscape) and (max-width: 812px) {
  .shoppingGlyph {
    color: inherit;
    width: 13px;
    height: 13px;
  }

  .menuBtnText {
    font-size: 1vw;
    line-height: 0.7vw;
    letter-spacing: 2.3px;
    color: inherit;
    margin-top: 0.1vh;
    margin-bottom: 1vh;
  }
  
  .menuBtnAlignLeft {
    margin-right: 0.7vw;
  }
  
  .menuBtnAlignRight {
    margin-left: 1vw;
  }
}