.transitionOverlayContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000000000000;
  pointer-events: none;
}

/*.rightBubble {
  position: fixed;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  right: -10%;
  top: -10%;
  width: 1000px;
  height: 1000px;
  border-radius: 500px;
  background-color: #131414;
  z-index: 10000000000009;
}

.leftBubble {
  position: fixed;
  left: -10%;
  top: 50%;
  transform: translate(0, -30%);
  background-color: #a6513f;
  width: 1400px;
  height: 1400px;
  border-radius: 700px;
}*/

.rightBubble {
  position: fixed;
  width: 120vw;
  height: 120vw;
  border-radius: 60vw;
  right: 150%;
  top: 180%;
  transform: translate(50%, -50%);
  -webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  background-color: #131414;
  z-index: 10000000000009;
}

.leftBubble {
  position: fixed;
  width: 120vw;
  height: 120vw;
  border-radius: 60vw;
  right: -35%;
  top: -100%;
  transform: translate(50%, -50%);
  -webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  background-color: #a6513f;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .rightBubble {
    width: 200vw;
    height: 200vw;
    border-radius: 100vw;
  }
  
  .leftBubble {
    width: 200vw;
    height: 200vw;
    border-radius: 100vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .rightBubble {
    width: 240vw;
    height: 240vw;
    border-radius: 120vw;
  }
  
  .leftBubble {
    width: 240vw;
    height: 240vw;
    border-radius: 120vw;
  }
}