.historyHeader {
  width: 95vw;
  height: auto;
  margin-top: 20vh;
  border-bottom: 1px solid rgba(51,51,51,0.4);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 4vh;
}

.historyHeaderTitle {
  font-family: "titleFont";
  color: #333;
  font-size: 5.5vw;
  margin-top: 0;
  margin-bottom: 0;
}

.historyCollectionsContainer {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8vh;
  margin-bottom: 0;
}

.historyCollectionContainer {
  width: 95vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.historyCollectionImg {
  width: 100%;
  height: 90vh;
  object-fit: cover;
  margin-bottom: 8vh;
  transition: all 0.3s ease-out;
}

.historyCollectionContainerTitle {
  position: absolute;
  bottom: 15vh;
  left: 3vw;
  font-family: "titleFont";
  color: #fff;
  font-size: 5.5vw;
  margin-top: 0;
  margin-bottom: 0;
}

.collectionContainerClickable {
  cursor: pointer;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .historyHeader {
    margin-top: 12vh;
    padding-bottom: 4vh;
  }
  
  .historyHeaderTitle {
    font-size: 8vw;
  }
  
  .historyCollectionsContainer {
    margin-top: 5vh;
  }
  
  .historyCollectionContainer {
    width: 95vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  
  .historyCollectionImg {
    width: 100%;
    height: 45vh;
    margin-bottom: 8vh;
  }
  
  .historyCollectionContainerTitle {
    position: absolute;
    bottom: 12vh;
    font-size: 8vw;
  }
  
  .collectionContainerClickable {
    cursor: pointer;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .historyHeader {
    margin-top: 13vh;
    padding-bottom: 3vh;
  }
  
  .historyHeaderTitle {
    font-size: 10vw;
  }
  
  .historyCollectionsContainer {
    margin-top: 4vh;
  }
  
  .historyCollectionContainer {
    width: 95vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  
  .historyCollectionImg {
    width: 100%;
    height: 45vh;
    margin-bottom: 8vh;
  }
  
  .historyCollectionContainerTitle {
    position: absolute;
    bottom: 12vh;
    font-size: 10vw;
  }
  
  .collectionContainerClickable {
    cursor: pointer;
  }
}