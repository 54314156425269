.singleHistoryCollectionHeader {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-top: 5vh;
  margin-bottom: 15vh;
}

.singleHistoryCollectionImg {
  width: 95vw;
  height: 40vw;
  object-fit: cover;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleHistoryCollectionHeader {
    margin-top: 4vh;
    margin-bottom: 8vh;
  }
  
  .singleHistoryCollectionImg {
    height: 45vh;
  }
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleHistoryCollectionHeader {
    margin-top: 5vh;
    margin-bottom: 8vh;
  }
  
  .singleHistoryCollectionImg {
    width: 95vw;
    height: 55vh;
    object-fit: cover;
  }
}