.overlayMenu {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #131414;
  z-index: 1000000000000;
  overflow-x: hidden;
  pointer-events: all;
  height: 100%;
}

.overlayInner {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.peekContainer {
  position: relative;
  top: 0;
  right: 0;
  width: 35vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.peekCloseButton {
  display: flex;
  position: absolute;
  top: 3vh;
  right: 8%;
  width: 2.5vh;
  height: auto;
  object-fit: cover;
  transition: opacity 0.3s ease-out;
  z-index: 1000000000001;
}

.peekProductList {
  flex: 1;
  display: block;
  margin-top: 10vh;
  overflow: hidden;
  width: 85%;
  margin-left: 7.5%;
  padding-right: 7.5%;
  position: relative;
}

.peekProductListInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.peekProductListInner::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.peekProductListInner {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.peekProductListEmpty {
  width: 100%;
  flex: 1;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.peekProductListEmptyText {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.4vw;
  line-height: 2vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  width: 50%;
  text-align: left;
}

.peekProductItem {
  width: 99%;
  height: auto;
  margin-bottom: 4.5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.peekProductImg {
  width: 25%;
  height: 18vh;
  object-fit: cover;
  margin-right: 1vw;
}

.peekProductInfo {
  flex: 1;
  width: calc(75% - 1vw);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.peekProductInfoMain {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.peekProductInfoTitleContainer {
  width: calc(100% - 1vw - 4vh);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 1vw;
}

.peekProductInfoTitle {
  font-family: 'titleFont';
  font-size: 1.5vw;
  color: #fff;
  margin-top: -1vh;
  margin-bottom: 1vh;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.peekProductInfoSub {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.peekProductRemoveButton {
  height: 4vh;
  width: auto;
  object-fit: cover;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.peekProductRemoveButton:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.peekProductInfoSecondary {
  margin-top: 4vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.peekProductInfoSecondaryExtra {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.peekProductInfoSecondarySize {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 1vh;
}

.peekProductInfoSecondaryQuantity {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.peekProductQuantityContainer {
  width: auto;
  height: 4.2vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e1e1e1;
  margin-left: 1vw;
}

.peekProductQuantityIcon {
  color: #fff;
  height: 2.8vh;
  width: 2.8vh;
  cursor: pointer;
}

.peekProductQuantityText {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.8vw;
  margin-right: 0.8vw;
}

.peekProductPrice {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.1vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  text-align: right;
}

.peekInfoContainer {
  width: 85%;
  height: auto;
  margin-bottom: 5vh;
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.peekSubtotal {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 2vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 2vh;
  text-align: right;
  width: 100%;
}

.peekButton {
  border: none;
  width: 13vw;
  height: 7vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 0.8vw;
  line-height: 0.8vw;
  color: #333;
  background-color: #fff;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.peekButton:hover {
  opacity: 0.8;
}

.peekButton:disabled {
  opacity: 0.3;
}

.peekButton:focus {
  outline: none;
}

.checkoutContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .peekContainer {
    width: 75vw;
  }
  
  .peekCloseButton {
    display: flex;
    position: absolute;
    top: 3vh;
    right: 7.5%;
    width: 2.5vh;
    height: auto;
    object-fit: cover;
    transition: opacity 0.3s ease-out;
  }
  
  .peekProductList {
    margin-top: 12vh;
  }
  
  .peekProductListEmptyText {
    font-size: 2.8vw;
    line-height: 4.2vw;
    width: 65%;
  }
  
  .peekProductItem {
    margin-bottom: 4vh;
  }
  
  .peekProductImg {
    width: 25%;
    height: 15vh;
    object-fit: cover;
    margin-right: 2vw;
  }
  
  .peekProductInfo {
    width: calc(75% - 2vw);
  }
  
  .peekProductInfoTitleContainer {
    width: calc(100% - 2vw - 4vh);
    margin-right: 2vw;
  }
  
  .peekProductInfoTitle {
    font-size: 3vw;
    margin-top: -0.5vh;
    margin-bottom: 1.5vh;
  }
  
  .peekProductInfoSub {
    font-size: 2vw;
  }
  
  .peekProductRemoveButton {
    height: 3.5vh;
  }
  
  .peekProductRemoveButton:hover {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
  }
  
  .peekProductInfoSecondary {
    margin-top: 4vh;
  }
  
  .peekProductInfoSecondarySize {
    font-size: 1.9vw;
    margin-bottom: 1vh;
  }
  
  .peekProductInfoSecondaryQuantity {
    font-size: 1.9vw;
  }
  
  .peekProductQuantityContainer {
    height: 3vh;
    margin-left: 2vw;
  }
  
  .peekProductQuantityIcon {
    height: 2vh;
    width: 2vh;
  }
  
  .peekProductQuantityText {
    font-size: 1.9vw;
    margin-left: 1.2vw;
    margin-right: 1.2vw;
  }
  
  .peekProductPrice {
    font-size: 2.5vw;
    margin-bottom: 0.5vh;
  }
  
  .peekInfoContainer {
    width: 85%;
  }
  
  .peekSubtotal {
    font-size: 3.2vw;
    line-height: 4.5vw;
    margin-bottom: 2.3vh;
  }
  
  .peekButton {
    width: 30vw;
    height: 5vh;
    font-size: 1.5vw;
    line-height: 1.5vw;
  }
  
  .peekButton:hover {
    opacity: 1;
  }
  
  .peekButton:disabled {
    opacity: 0.3;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .peekContainer {
    width: 100vw;
  }
  
  .peekCloseButton {
    display: flex;
    position: absolute;
    top: 2.5vh;
    right: 5%;
    width: 2.5vh;
    height: auto;
    object-fit: cover;
  }
  
  .peekProductList {
    width: 90%;
    margin-top: 12vh;
  }

  .peekProductListInner {
    display: block;
  }
  
  .peekProductListEmptyText {
    font-size: 4.5vw;
    line-height: 7vw;
    width: 75%;
  }
  
  .peekProductItem {
    margin-bottom: 4vh;
  }
  
  .peekProductImg {
    width: 25%;
    margin-right: 2.5vw;
  }
  
  .peekProductInfo {
    width: calc(75% - 2.5vw);
  }
  
  .peekProductInfoTitleContainer {
    width: calc(100% - 2.5vw - 4vh);
    margin-right: 2.5vw;
  }
  
  .peekProductInfoTitle {
    font-size: 5vw;
    margin-top: 0vh;
    margin-bottom: 1.2vh;
  }
  
  .peekProductInfoSub {
    font-size: 3.5vw;
  }
  
  .peekProductInfoSecondary {
    margin-top: 2vh;
  }
  
  .peekProductInfoSecondarySize {
    font-size: 3vw;
    margin-bottom: 1vh;
  }
  
  .peekProductInfoSecondaryQuantity {
    font-size: 3vw;
  }
  
  .peekProductQuantityContainer {
    height: 4vh;
    margin-left: 2vw;
    border: 1px solid rgba(255,255,255,0.5);
  }
  
  .peekProductQuantityIcon {
    height: 2.5vh;
    width: 2.5vh;
  }
  
  .peekProductQuantityText {
    font-size: 3vw;
    margin-left: 2vw;
    margin-right: 2vw;
  }
  
  .peekProductPrice {
    font-size: 3.5vw;
    margin-bottom: 0.5vh;
  }
  
  .peekInfoContainer {
    width: 90%;
  }
  
  .peekSubtotal {
    font-size: 4.5vw;
    line-height: 7vw;
    margin-bottom: 2.5vh;
  }
  
  .peekButton {
    width: 60vw;
    height: 7vh;
    font-size: 3vw;
    line-height: 3vw;
  }
}