.smallInputSimpleLabel {
  margin-bottom: 0.6vh;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 0.9vw;
  line-height: 0.9vw;
  color: #333;
}

.smallInputSimple {
  color: #fff;
  background-color: #131414;
  border-bottom: 1px solid #fff;
  border-right: none;
  border-top: none;
  border-left: none;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 1.2vw;
  margin-top: 0;
  margin-bottom: 1.5vh;
  height: 2vw;
  width: 100%;
  padding-left: 0.5vw;
}

.smallInputSimple::placeholder {
  color: rgba(255, 255, 255, 1);
}

.smallInputSimple:focus {
  outline: none;
}

@media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {

  .smallInputSimpleLabel {
    margin-bottom: 0.6vh;
    font-size: 1.7vw;
    line-height: 1.7vw;
  }
  
  .smallInputSimple {
    font-size: 2.2vw;
    line-height: 2.2vw;
    height: 3.5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {

  .smallInputSimpleLabel {
    margin-bottom: 0.7vh;
    font-size: 2.8vw;
    line-height: 2.8vw;
  }
  
  .smallInputSimple {
    font-size: 4vw;
    line-height: 4vw;
    height: 8vw;
  }
}