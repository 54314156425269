.scrollArea {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: scroll;
  display: block;
  z-index: 100000;
  pointer-events: all;
}

.sections {
  width: 100vw;
  position: relative;
}

.homeOuter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

.homeContainer {
  position: absolute;
  left: 0;
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.homeHeader {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20vh;
}

.homeHeaderTitleContainer {
  width: 95%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.homeHeaderTitleLeft {
  font-family: 'titleFont';
  font-size: 7.5vw;
  line-height: 7.5vw;
  margin-top: 0;
  margin-bottom: 0;
  color: #a6513f;
}

.homeHeaderTitleRight {
  font-family: 'titleFont';
  font-size: 7.5vw;
  line-height: 7.5vw;
  margin-top: 0;
  margin-bottom: 0;
  color: #a6513f;
  align-self: flex-end;
  z-index: 2;
}

.homeHeaderImgWrapper {
  margin-top: -7vh;
  width: 95%;
  height: 100vh;
  overflow: hidden;
}

.homeHeaderImg {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  filter: brightness(0.8);
}

.homeSubheader {
  margin-top: 28vh;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.homeSubheaderImgWrapperLeft {
  width: 50%;
  height: 140vh;
  overflow: hidden;
}

.homeSubheaderImgLeft {
  width: 100%;
  height: 140vh;
  object-fit: cover;
}

.homeSubheaderImgWrapperRight {
  width: 40%;
  height: 70vh;
  overflow: hidden;
}

.homeSubheaderImgRight {
  width: 100%;
  height: 70vh;
  object-fit: cover;
  object-position: 0% 30%;
}

.homePhraseContainer {
  width: 20vw;
  height: 50vh;
  border-top: 3px solid #a6513f;
  align-self: flex-end;
  margin-right: 15vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3vh;
  margin-top: -5vh;
}

.homePhrase {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.5vw;
  line-height: 3vw;
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(51,51,51,0.7);
  align-self: flex-end;
}

.bigPhrase {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 5vw;
  margin-top: 0;
  margin-bottom: 0;
  color: #333;
}

.newsletterContainer {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15vh;
  background-color: #131414;
  position: relative;
}

.newsletterImgWrapper {
  width: 40%;
  height: 90vh;
  overflow: hidden;
}

.newsletterImg {
  width: 100%;
  height: 90vh;
  object-fit: cover;
}

.newsletterInfoContainer {
  width: 60%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.newsletterInfoTitle {
  font-family: 'titleFont';
  font-size: 4vw;
  line-height: 5vw;
  text-align: right;
  margin-top: 7vh;
  margin-bottom: 7vh;
  color: #fff;
  width: 90%;
  margin-right: 5%;
}

.newsletterInfoSub {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.3vw;
  line-height: 2.3vw;
  text-align: right;
  margin-top: 0;
  margin-bottom: 7vh;
  color: #fff;
  width: 90%;
  margin-right: 5%;
}

.newsletterEmailInput {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 2.7vw;
  line-height: 4.3vw;
  margin-top: 0;
  margin-bottom: 0;
  color: #fff;
  width: 90%;
  border-bottom: 1px solid rgba(255,255,255,0.7);
  margin-right: 5%;
  background-color: transparent;
  border-right: none;
  border-top: none;
  border-left: none;
  margin-bottom: 4vh;
}

.newsletterEmailInput:focus {
  outline: none;
}

.newsletterEmailInput::placeholder {
  color: rgba(255,255,255,0.7);
}

.newsletterSubmitButton {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.8vw;
  margin-top: 0;
  margin-bottom: 7vh;
  color: #fff;
  cursor: pointer;
  margin-right: 5%;
  background-color: transparent;
  border: none;
}

.homeDecorationImgWrapper {
  width: 95vw;
  height: 100vh;
  overflow: hidden;
  margin-top: 30vh;
  margin-bottom: 30vh;
}

.homeDecorationImg {
  width: 95vw;
  height: 100vh;
  object-fit: cover;
}

.homeNextContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  background-color: #fff;
  position: relative;
}

.homeNextTitleContainer {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeNextTitle {
  font-family: 'titleFont';
  font-size: 7.5vw;
  line-height: 7.5vw;
  word-spacing: 1.5vw;
  margin-top: 0;
  margin-bottom: 3vh;
  color: #333;
}

.homeNextLine {
  width: 100vw;
  height: 0;
  border-bottom: 0.1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transition: width 0.5s ease-out;
}

.exploreMoreOverlay {
  position: absolute;
  bottom: 25vh;
  left: 0;
  width: 100vw;
  height: 50vh;
  cursor: pointer;
}

.homeTransitionImg {
  position: fixed;
  left: 50%;
  height: 100vh;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  object-fit: cover;
  filter: brightness(0.5);
  z-index: 100;
}

.collectionCTAContainer {
  position: absolute;
  top: 85vh;
  right: 20vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.homeArrowGlyph {
  width: 17px;
  height: 17px;
  color: #e1e1e1;
}

.ctaText {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.3vw;
  margin-top: 0;
  margin-bottom: 0;
  color: #e1e1e1;
}

.ctaArrowContainer {
  width: 4vw;
  height: 4vw;
  border-radius: 3vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: -2.5vw;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  border: 1px solid rgba(225,225,225,0.3);
}

.newsletterOverlay {
  position: absolute;
  top: calc(15vw + 344vh); /* Full sum of all previous elements */
  left: 0;
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.subheaderOverlay {
  position: absolute;
  top: calc(141vh + 15vw); /* Full sum of all previous elements */
  left: 0;
  width: 100vw;
  height: 140vh;
}

.subheaderPlusOverlay {
  position: absolute;
  left: 48%;
  bottom: 15vh;
  width: 7vw;
  height: 7vw;
  border-radius: 3.5vw;
  cursor: pointer;
}

.subheaderPlusOverlay:hover {
  width: 10vw;
  height: 10vw;
  border-radius: 5vw;
}

.animaLogo {
  height: 2.5vh;
  width: auto;
  position: absolute;
  bottom: 3vh;
  right: 6vw;
  object-fit: cover;
}

.leLogo {
  height: 2.5vh;
  width: auto;
  position: absolute;
  bottom: 3vh;
  left: 6vw;
  object-fit: cover;
}

.footerInfo {
  position: absolute;
  bottom: 3vh;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 0.9vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .homeHeader {
    margin-top: 15vh;
  }
  
  .homeHeaderTitleLeft {
    font-family: 'titleFont';
    font-size: 7.5vw;
    line-height: 7.5vw;
    margin-top: 0;
    margin-bottom: 0;
    color: #a6513f;
    z-index: 2;
  }
  
  .homeHeaderTitleRight {
    font-family: 'titleFont';
    font-size: 7.5vw;
    line-height: 7.5vw;
    margin-top: 0;
    margin-bottom: 0;
    color: #a6513f;
    align-self: flex-end;
    z-index: 2;
  }
  
  .homeHeaderImgWrapper {
    margin-top: -4vh;
    width: 90%;
    height: 50vh;
    overflow: hidden;
  }
  
  .homeHeaderImg {
    height: 50vh;
  }
  
  .homeSubheader {
    margin-top: 15vh;
    width: 100vw;
  }
  
  .homeSubheaderImgWrapperLeft {
    height: 80vh;
  }
  
  .homeSubheaderImgLeft {
    height: 80vh;
  }
  
  .homeSubheaderImgWrapperRight {
    height: 30vh;
  }
  
  .homeSubheaderImgRight {
    height: 30vh;
    object-position: 0 50%;
  }
  
  .homePhraseContainer {
    width: 30vw;
    height: 20vh;
    margin-right: 10vw;
  }
  
  .homePhrase {
    font-size: 2vw;
    line-height: 4vw;
  }
  
  .newsletterContainer {
    margin-top: 10vh;
  }

  .newsletterImgWrapper {
    width: 40%;
    height: 40vh;
    overflow: hidden;
  }
  
  .newsletterImg {
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
  
  .newsletterInfoTitle {
    text-align: right;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
  
  .newsletterInfoSub {
    font-size: 2.1vw;
    line-height: 3.5vw;
    margin-bottom: 4vh;
  }
  
  .newsletterEmailInput {
    font-size: 2.9vw;
    line-height: 4.3vw;
    width: 85%;
    margin-bottom: 2vh;
  }
  
  .newsletterSubmitButton {
    font-size: 2.4vw;
    margin-top: 0;
    margin-bottom: 4vh;
  }
  
  .homeDecorationImgWrapper {
    width: 95vw;
    height: 45vh;
    overflow: hidden;
    margin-top: 20vh;
    margin-bottom: 15vh;
  }
  
  .homeDecorationImg {
    width: 95vw;
    height: 45vh;
    object-fit: cover;
  }
  
  .homeNextContainer {
    width: 100vw;
    height: 60vh;
    margin-top: 0;
  }
  
  .homeNextTitle {
    font-family: 'titleFont';
    font-size: 7.5vw;
    line-height: 7.5vw;
    margin-top: 0;
    margin-bottom: 3vh;
    color: #333;
  }
  
  .homeNextLine {
    width: 100vw;
    height: 0;
    border-bottom: 0.1px solid rgba(225, 225, 225, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transition: width 0.5s ease-out;
  }
  
  .exploreMoreOverlay {
    position: absolute;
    bottom: 15vh;
    left: 0;
    width: 100vw;
    height: 30vh;
    cursor: pointer;
  }
  
  .homeTransitionImg {
    position: fixed;
    left: 50%;
    height: 100vh;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    object-fit: cover;
    filter: brightness(0.5);
  }
  
  .collectionCTAContainer {
    top: 50vh;
    right: 23vw;
  }

  .homeArrowGlyph {
    width: 17px;
    height: 17px;
    color: #e1e1e1;
  }
  
  .ctaText {
    font-size: 2.3vw;
  }
  
  .ctaArrowContainer {
    border-radius: 7vw;
  }
  
  .newsletterOverlay {
    position: absolute;
    top: calc(15vw + 184vh); /* Full sum of all previous elements */
    left: 0;
    width: 100vw;
    height: 40vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .subheaderOverlay {
    position: absolute;
    top: calc(76vh + 15vw); /* Full sum of all previous elements */
    left: 0;
    width: 100vw;
    height: 80vh;
  }
  
  .subheaderPlusOverlay {
    position: absolute;
    left: 43%;
    bottom: 12vh;
    width: 14vw;
    height: 14vw;
    border-radius: 7vw;
    cursor: pointer;
  }
  
  .subheaderPlusOverlay:hover {
    width: 18vw;
    height: 18vw;
    border-radius: 9vw;
  }

  .footerInfo {
    font-size: 1.4vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .homeHeader {
    width: 100vw;
    margin-top: 17vh;
  }
  
  .homeHeaderTitleLeft {
    font-size: 8.5vw;
    line-height: 8.5vw;
  }
  
  .homeHeaderTitleRight {
    font-size: 8.5vw;
    line-height: 8.5vw;
  }
  
  .homeHeaderImgWrapper {
    margin-top: 2vh;
    width: 95%;
    height: 60vh;
  }
  
  .homeHeaderImg {
    height: 60vh;
  }
  
  .homeSubheader {
    margin-top: 14vh;
  }
  
  .homeSubheaderImgWrapperLeft {
    height: 60vh;
  }
  
  .homeSubheaderImgLeft {
    height: 60vh;
  }
  
  .homeSubheaderImgWrapperRight {
    height: 30vh;
  }
  
  .homeSubheaderImgRight {
    height: 30vh;
  }
  
  .homePhraseContainer {
    width: 40vw;
    height: 20vh;
    margin-right: 5vw;
    padding-top: 2vh;
    margin-top: -2vh;
  }
  
  .homePhrase {
    font-size: 3vw;
    line-height: 6vw;
  }
  
  .newsletterContainer {
    width: 100vw;
    height: auto;
    flex-direction: column;
    margin-top: 10vh;
  }
  
  .newsletterImgWrapper {
    width: 100%;
    height: 40vh;
  }
  
  .newsletterImg {
    width: 100%;
    height: 40vh;
  }
  
  .newsletterInfoContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  
  .newsletterInfoTitle {
    font-size: 8vw;
    line-height: 10vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    text-align: left;
  }
  
  .newsletterInfoSub {
    font-size: 4vw;
    line-height: 7vw;
    margin-bottom: 7vh;
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
  }
  
  .newsletterEmailInput {
    font-size: 5.5vw;
    line-height: 9vw;
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 2vh;
    align-self: center;
  }
  
  .newsletterSubmitButton {
    font-size: 5vw;
    margin-bottom: 7vh;
  }
  
  .homeDecorationImgWrapper {
    width: 95vw;
    height: 60vh;
    margin-top: 15vh;
    margin-bottom: 15vh;
  }
  
  .homeDecorationImg {
    width: 95vw;
    height: 60vh;
  }
  
  .homeNextContainer {
    height: 50vh;
    margin-top: 0;
  }
  
  .homeNextTitle {
    font-size: 9vw;
    line-height: 9vw;
  }
  
  .exploreMoreOverlay {
    bottom: 12.5vh;
    height: 25vh;
  }
  
  .homeTransitionImg {
    position: fixed;
    left: 50%;
    height: 100vh;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    object-fit: cover;
    filter: brightness(0.5);
  }
  
  .collectionCTAContainer {
    top: 70vh;
    right: 17vw;
  }

  .homeArrowGlyph {
    width: 13px;
    height: 13px;
    color: #e1e1e1;
  }
  
  .ctaText {
    font-size: 3.5vw;
    line-height: 4.5vw;
  }
  
  .ctaArrowContainer {
    width: 6vw;
    height: 6vw;
    border-radius: 9vw;
    right: -2.5vw;
  }
  
  .newsletterOverlay {
    position: absolute;
    top: calc(17vw + 183vh); /* Full sum of all previous elements */
    left: 0;
    width: 100vw;
    height: auto;
    flex-direction: column;
  }
  
  .subheaderOverlay {
    position: absolute;
    top: calc(17vw + 93vh); /* Full sum of all previous elements */
    height: 60vh;
  }
  
  .subheaderPlusOverlay {
    bottom: 6vh;
    width: 16vw;
    height: 16vw;
    border-radius: 8vw;
  }
  
  .subheaderPlusOverlay:hover {
    width: 10vw;
    height: 10vw;
    border-radius: 5vw;
  }
  
  .footerInfo {
    font-size: 2vw;
  }
}