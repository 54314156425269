.homeSubheaderGoBtn {
  position: absolute;
  left: 48%;
  bottom: 15vh;
  width: 7vw;
  height: 7vw;
  border-radius: 3.5vw;
  border: 1px solid rgba(51,51,51,0.7);
  cursor: pointer;
}

.homeGoBtnVert {
  position: relative;
  width: 0;
  height: 80%;
  left: 50%;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  border-right: 1px solid rgba(51,51,51,0.7);
}

.homeGoBtnHor {
  position: absolute;
  width: 80%;
  height: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  border-top: 1px solid rgba(51,51,51,0.7);
}

@media (orientation: portrait) and (max-width: 1024px) {
  .homeSubheaderGoBtn {
    position: absolute;
    left: 43%;
    bottom: 12vh;
    width: 14vw;
    height: 14vw;
    border-radius: 7vw;
    border: 1px solid rgba(51,51,51,0.7);
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .homeSubheaderGoBtn {
    bottom: 6vh;
    width: 16vw;
    height: 16vw;
    border-radius: 8vw;
  }
}