.menuOuter {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000002;
  overflow: hidden;
  height: 100%;
}

.menuContainerBg {
  position: relative;
  top: 50%;
  left: -10%;
  background-color: #131414;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}

.menuContainer {
  position: fixed;
  z-index: 10000010;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.trails-text {
  width: 100%;
  height: 5.8vw;
  line-height: 5.8vw;
  font-family: 'titleFont';
  font-size: 5.8vw;
  will-change: transform, opacity;
  overflow: hidden;
  transition: right 0.3s ease-out;
  cursor: pointer;
}

.trails-text > div {
  overflow: hidden;
}

.menuItem {
  height: auto;
  width: auto;
  margin-bottom: 3vh;
  position: relative;
  overflow: hidden;
  padding-left: 3vw;
}

.menuText {
  position: relative;
  top: 0;
  left: 0;
  font-family: 'titleFont';
  font-size: 7.5vw;
  line-height: 6.5vw;
  color: #a6513f;
  margin-top: 0;
  margin-bottom: 3vh;
  cursor: pointer;
  transition: letter-spacing 0.3s ease-out;
}

.menuItem:hover .menuText {
  letter-spacing: 0.3vw;
}

.menuItemHoverLine {
  position: absolute;
  top: 80%;
  left: 0;
  width: 0%;
  height: 0;
  border-bottom: 1px solid #a6513f;
  transition: width 0.3s ease-out;
}

.menuText:hover .menuItemHoverLine {
  width: 100%;
}

.menuItemTextSub {
  position: absolute;
  top: 0;
  left: -1.5vw;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1vw;
  color: #a6513f;
  margin-top: 0;
  margin-bottom: 0;
}

.menuExtraContainer {
  position: absolute;
  bottom: 6vh;
  left: 6vw;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.menuExtraText {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.5vw;
  color: #a6513f;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 1.5vw;
  cursor: pointer;
  transition: filter 0.3s ease-out;
  position: relative;
}

.menuExtraUnderline {
  width: 0%;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #a6513f;
  transition: width 0.3s ease-out;
}

.menuExtraText:hover .menuExtraUnderline {
  width: 100%;
}

.menuExtraText:hover {
  filter: brightness(0.8);
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .menuContainerBg {
    left: -50%;
  }
  
  .trails-text {
    height: 5.8vw;
    line-height: 5.8vw;
    font-size: 5.8vw;
  }
  
  .menuItem {
    height: auto;
    width: auto;
    margin-bottom: 5vh;
    position: relative;
    overflow: hidden;
    padding-left: 3vw;
  }
  
  .menuText {
    font-size: 10.3vw;
    line-height: 8.5vw;
    margin-bottom: 2.5vh;
  }
  
  .menuItem:hover .menuText {
    letter-spacing: 0;
  }
  
  .menuItemHoverLine {
    display: none;
  }
  
  .menuItemTextSub {
    left: -2.5vw;
    font-size: 1.7vw;
  }
  
  .menuExtraText {
    font-size: 2.7vw;
    margin-right: 2.5vw;
  }
  
  .menuExtraUnderline {
    display: none;
  }
  
  .menuExtraText:hover {
    filter: brightness(1);
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .menuContainer {
    align-items: flex-start;
    padding-left: 6vw;
  }

  .menuItem {
    margin-bottom: 5vh;
  }
  
  .menuText {
    font-size: 13.3vw;
    line-height: 9.5vw;
    margin-bottom: 2.5vh;
  }
  
  .menuItemTextSub {
    left: -3vw;
    font-size: 2.4vw;
  }
  
  .menuExtraText {
    font-size: 5vw;
    margin-right: 3.5vw;
  }
  
}