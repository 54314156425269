.paymentSuccessOuter {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.paymentSuccessContainer {
  width: 88vw;
  padding-left: 6vw;
  padding-right: 6vw;
  height: calc(100% - 27vh);
  margin-top: 20vh;
  padding-bottom: 7vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.paymentSuccessColumn {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.orderConfirmationLoadingMessageContainer {
  width: 60%;
  padding-left: 20%;
  padding-right: 20%;
  height: 100%;
  text-align: center;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 2vw;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.successLoadingIcon {
  width: 15vw;
  height: 15vw;
  color: #333;
  margin-bottom: 6vh;
}

.orderConfirmationContainer {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.orderConfirmationTitle {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 2vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 1vh;
}

.orderConfirmationSub {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.2vw;
  line-height: 2.4vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.orderConfirmationButton {
  width: 18vw;
  height: 7vh;
  background-color: #333;
  margin-top: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  color: #fff;
  font-size: 0.8vw;
  line-height: 0.8vw;
  text-decoration: none;
  transition: opacity 0.3s ease-out;
}

.orderConfirmationButton:hover {
  opacity: 0.8;
}

.orderConfirmationButtonIcon {
  width: 17px;
  height: 17px;
  color: #fff;
  margin-right: 1vw;
}

.orderConfirmationAddressContainer {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.orderConfirmationAddressTitle {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.3vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 1vh;
}

.orderConfirmationAddressSub {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.1vw;
  line-height: 2.2vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.paymentSuccessProductsColumn {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .paymentSuccessContainer {
    height: calc(100% - 22vh);
    margin-top: 15vh;
    padding-bottom: 7vh;
  }
  
  .paymentSuccessColumn {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .orderConfirmationLoadingMessageContainer {
    width: 70%;
    padding-left: 15%;
    padding-right: 15%;
    font-size: 3.3vw;
  }
  
  .successLoadingIcon {
    width: 23vw;
    height: 23vw;
    margin-bottom: 5vh;
  }
  
  .orderConfirmationTitle {
    font-size: 3.5vw;
  }
  
  .orderConfirmationSub {
    font-size: 2vw;
    line-height: 4vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }

  .orderConfirmationButton {
    width: 33vw;
    height: 5vh;
    font-size: 1.4vw;
    line-height: 1.4vw;
  }
  
  .orderConfirmationButton:hover {
    opacity: 1;
  }
  
  .orderConfirmationButtonIcon {
    margin-right: 2vw;
  }

  .paymentSuccessProductsColumn {
    width: 50%;
  }
  
  .orderConfirmationAddressTitle {
    font-size: 2.5vw;
  }
  
  .orderConfirmationAddressSub {
    font-size: 2vw;
    line-height: 4vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .paymentSuccessContainer {
    width: 88vw;
    padding-left: 6vw;
    padding-right: 6vw;
    height: calc(100% - 24vh);
    margin-top: 17vh;
    padding-bottom: 7vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .paymentSuccessColumn {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 4vh;
  }
  
  .orderConfirmationLoadingMessageContainer {
    width: 85%;
    padding-left: 7.5%;
    padding-right: 7.5%;
    height: 100%;
    font-size: 5vw;
  }
  
  .successLoadingIcon {
    width: 35vw;
    height: 35vw;
  }
  
  .orderConfirmationContainer {
    width: 100%;
    margin-bottom: 4vh;
  }
  
  .orderConfirmationTitle {
    font-family: 'Helvetica Neue';
    font-weight: 700;
    font-size: 7vw;
  }
  
  .orderConfirmationSub {
    font-size: 4vw;
    line-height: 8vw;
  }

  .orderConfirmationButton {
    width: 88vw;
    height: 8vh;
    font-size: 3vw;
    line-height: 3vw;
  }
  
  .orderConfirmationButtonIcon {
    margin-right: 4vw;
  }
  
  .orderConfirmationAddressContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .orderConfirmationAddressTitle {
    font-size: 4.7vw;
  }
  
  .orderConfirmationAddressSub {
    font-size: 4vw;
    line-height: 8vw;
  }
  
  .paymentSuccessProductsColumn {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}