@font-face {
  font-family: 'titleFont';
  src: url('./assets/fonts/title.otf') format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  src: url('./assets/fonts/HelveticaNeue-Bold2.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  src: url('./assets/fonts/HelveticaNeue-Light2.ttf') format('truetype');
}

* {
  -webkit-overflow-scrolling: touch;
}

#root, html, body {
  width: 100vw;
}

#root {
  padding: 1px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.mainContainer {
  width: 88vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}