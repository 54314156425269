.preloaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #131414;
  z-index: 100000000000;
  overflow: hidden;
  pointer-events: all;
}

.preloaderNav {
  position: absolute;
  top: 4vh;
  width: 88vw;
  left: 6vw;
  height: 8vh;
  overflow: hidden;
}

.preloaderLogo {
  height: 5vh;
  width: auto;
  object-fit: cover;
  filter: brightness(0) invert(1);
  position: absolute;
  bottom: 0;
  left: 0;
}

.preloaderYear {
  font-family: "titleFont";
  color: rgba(255,255,255,0.9);
  font-size: 3.5vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  position: absolute;
  bottom: 0;
  left: 6.5vh;
}

.preloaderLoadContainer {
  position: absolute;
  bottom: 10vh;
  left: 62vw;
  width: 38vw;
  height: 15.5vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.preloaderProgress {
  width: 15vw;
  height: 15vw;
  border-radius: 7.5vw;
  border: 1px solid rgba(255,255,255,0.2);
}

.preloaderButton {
  font-family: "titleFont";
  color: rgba(255,255,255,1);
  font-size: 4.3vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -4.5vw;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.preloaderButton:hover {
  opacity: 0.7;
}

.internetExplorerWarning {
  width: 40vw;
  position: fixed;
  bottom: 10vh;
  right: 5vw;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 2.8vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .preloaderNav {
    position: absolute;
    top: 3vh;
    width: 88vw;
    left: 6vw;
    height: 8vh;
    overflow: hidden;
  }
  
  .preloaderLogo {
    height: 4vh;
  }
  
  .preloaderYear {
    font-size: 5.5vh;
    left: 5.5vh;
  }
  
  .preloaderLoadContainer {
    bottom: 10vh;
    left: 45vw;
    width: 65vw;
    height: 32vw;
  }
  
  .preloaderButton {
    font-size: 7vw;
  }
  
  .preloaderButton:hover {
    opacity: 1;
  }

  .internetExplorerWarning {
    width: 40vw;
    font-size: 2.5vw;
    line-height: 4vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .preloaderLoadContainer {
    bottom: 10vh;
    left: 42vw;
    width: 65vw;
    height: 32vw;
  }

  .internetExplorerWarning {
    width: 70vw;
    font-size: 4.5vw;
    line-height: 8vw;
  }
}