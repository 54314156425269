.notFoundContainer {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notFoundMainTitle {
  font-family: 'titleFont';
  font-size: 18vw;
  color: #333;
  margin-top: -12vh;
  margin-bottom: 8vh;
}

.notFoundMainSub {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.5vw;
  line-height: 2.5vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  width: 60%;
  text-align: center;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .notFoundMainTitle {
    font-size: 30vw;
    margin-top: -12vh;
    margin-bottom: 8vh;
  }
  
  .notFoundMainSub {
    font-size: 3vw;
    line-height: 6vw;
    width: 80%;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .notFoundMainTitle {
    font-size: 35vw;
    margin-top: -10vh;
    margin-bottom: 5vh;
  }
  
  .notFoundMainSub {
    font-size: 4vw;
    line-height: 9vw;
    width: 85%;
  }
}