.authContainer {
  width: 100%;
  height: calc(100% - 15vh);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12vh;
  margin-bottom: 3vh;
}

.signInContainer {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.signInTitle {
  width: 70%;
  height: auto;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 2.3vw;
  line-height: 3.6vw;
  color: #333;
  margin-top: 10vh;
  margin-bottom: 3vh;
}

.signInForm {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signInButton {
  margin-right: -1vw;
  align-self: flex-end;
  width: 13vw;
  height: 7vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #333;
  color: #333;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 0.9vw;
  line-height: 0.9vw;
  margin-top: 2vh;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.signInButton:focus {
  outline: none;
}

.signInButton:hover {
  opacity: 0.8;
}

.signInButton:disabled {
  opacity: 0.3;
}

.accountContainer {
  width: 100%;
  height: calc(100% - 15vh);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12vh;
  margin-bottom: 3vh;
}

.accountSidebar {
  margin-top: 10vh;
  width: 17vw;
  margin-left: 6vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 3vw;
  border-right: 2px solid #131414;
}

.accountSidebarLink {
  width: 80%;
  height: auto;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.4vw;
  line-height: 2.5vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 2.5vh;
  cursor: pointer;
  position: relative;
}

.mobileSidebarIcon {
  display: none;
}

.accountSidebarLinkDot {
  width: 10px;
  height: 10px;
  border-radius: 8px;
  border: 0.5px solid #131414;
  position: absolute;
  top: 50%;
  left: -1.5vw;
  transform: translate(0, -40%);
  -webkit-transform: translate(0, -40%);
  -moz-transform: translate(0, -40%);
  transition: opacity 0.5s ease-out;
  opacity: 0;
}

.accountSidebarLink:hover .accountSidebarLinkDot {
  opacity: 1;
}

.accountMainContainer {
  margin-top: 10vh;
  width: 68vw;
  margin-right: 6vw;
  margin-left: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.accountMainInfoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.accountMainInfoColumn {
  width: 45%;
  margin-right: 1vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleProductSelect {
  color: #fff;
  background-color: #131414;
  border-bottom: 1px solid #fff;
  border-right: none;
  border-top: none;
  border-left: none;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 1.5vw;
  margin-top: 0;
  margin-bottom: 2vh;
  height: 3.3vw;
  width: calc(100% + 1vw);
  padding-left: 1vw;
  padding-right: 1vw;
}

.singleProductSelect:focus {
  outline: none;
}

.singleProductSelectOption {
  font-family: 'Helvetica Neue';
  font-size: 1vw;
  line-height: 1vw;
  font-weight: 700;
  color: #333;
}

.accountOrdersContainer {
  width: 100%;
  height: 100%;
  display: block;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.accountOrdersContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.accountOrdersContainer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.collectionsTitleLine {
  width: 100%;
  height: auto;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5vh;
}

.collectionsTitle {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 2vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.collectionsListContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
}

.collectionHeaderLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-bottom: 1.5px solid #333;
}

.orderDateHeaderColumn {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.1vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  width: 15%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.orderLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-bottom: 1px solid #e1e1e1;
}

.orderDateLineColumn {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 15%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orderIdHeaderColumn {
  width: 30%;
}

.orderPriceHeaderColumn {
  width: 20%;
}

.orderStatusHeaderColumn {
  width: 25%;
}

.orderPrintHeaderColumn {
  width: 10%;
}

.orderStatusContainer {
  width: 80%;
  height: auto;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 0.7vw;
  border-radius: 5px;
}

.collectionLineIcon {
  width: 20px;
  height: 20px;
  color: #333;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.collectionLineIcon:hover {
  opacity: 0.7;
}

.forgotPasswordButton {
  align-self: flex-end;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 0.9vw;
  color: #333;
  margin-top: 2.5vh;
  margin-bottom: 0vh;
  cursor: pointer;
  margin-right: -1vw;
  transition: opacity 0.3s ease-out;
}

.forgotPasswordButton:hover {
  opacity: 0.5;
}

.loadingCollections {
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Helvetica Neue';
  font-size: 1.2vw;
  font-weight: 300;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.accountPolicyText {
  width: 80%;
  height: auto;
  text-align: right;
  align-self: flex-end;
  margin-right: -1vw;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 0.9vw;
  line-height: 1.5vw;
  color: #333;
  margin-top: 4vh;
  margin-bottom: 0;
  text-decoration: none;
}

.accountPolicyLink {
  color: #333;
  transition: opacity 0.3s ease-out;
}

.accountPolicyLink:hover {
  opacity: 0.5;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .authContainer {
    width: 100%;
    height: calc(100% - 8vh);
    margin-top: 5vh;
    margin-bottom: 3vh;
  }
  
  .signInTitle {
    width: 75%;
    font-size: 3vw;
    line-height: 4vw;
    margin-top: 10vh;
    margin-bottom: 3vh;
  }
  
  .signInForm {
    width: 75%;
  }
  
  .signInButton {
    margin-right: -1vw;
    width: 25vw;
    height: 5vh;
    font-size: 1.4vw;
    line-height: 1.4vw;
    margin-top: 1.5vh;
  }
  
  .signInButton:hover {
    opacity: 1;
  }

  .accountContainer {
    width: 100%;
    height: calc(100% - 8vh);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5vh;
    margin-bottom: 3vh;
  }
  
  .accountSidebar {
    margin-top: 10vh;
    width: 88vw;
    padding-left: 6vw;
    padding-right: 6vw;
    margin-left: 0;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 0;
    border-bottom: 1px solid #131414;
    border-right: none;
  }
  
  .accountSidebarLink {
    width: auto;
    height: auto;
    font-size: 1.7vw;
    line-height: 2.5vw;
    margin-bottom: 2.5vh;
  }
  
  .accountSidebarLinkDot {
    display: none;
  }
  
  .accountMainContainer {
    margin-top: 3vh;
    width: 88vw;
    margin-right: 6vw;
    margin-left: 6vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
  
  .singleProductSelect {
    font-size: 2.5vw;
    line-height: 2.5vw;
    margin-top: 0;
    margin-bottom: 2vh;
    height: 5.5vw;
  }
  
  .singleProductSelectOption {
    font-size: 2vw;
    line-height: 2vw;
  }

  .collectionsTitleLine {
    display: none;
  }

  .collectionLineIcon:hover {
    opacity: 1;
  }

  .orderDateHeaderColumn {
    font-size: 2.1vw;
    width: 15%;
  }
  
  .orderDateLineColumn {
    font-size: 2vw;
    width: 15%;
  }
  
  .orderIdHeaderColumn {
    width: 35%;
  }
  
  .orderPriceHeaderColumn {
    width: 20%;
  }
  
  .orderStatusHeaderColumn {
    width: 20%;
  }
  
  .orderPrintHeaderColumn {
    width: 10%;
  }
  
  .orderStatusContainer {
    width: 80%;
    height: auto;
    padding-top: 0.8vh;
    padding-bottom: 0.8vh;
    font-size: 1.3vw;
    border-radius: 5px;
  }

  .forgotPasswordButton {
    font-size: 1.7vw;
    margin-top: 3vh;
    margin-right: -0.7vw;
  }
  
  .forgotPasswordButton:hover {
    opacity: 1;
  }

  .loadingCollections {
    font-size: 2vw;
  }

  .accountPolicyText {
    width: 100%;
    margin-right: -1vw;
    font-size: 1.6vw;
    line-height: 2.7vw;
    color: #333;
    margin-top: 3vh;
  }
  
  .accountPolicyLink:hover {
    opacity: 1;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .authContainer {
    width: 100vw;
    height: calc(100% - 18vh);
    margin-top: 25vh;
    margin-bottom: 3vh;
    padding-bottom: 10vh;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .signInContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .signInTitle {
    width: 88%;
    font-size: 7vw;
    line-height: 10vw;
    margin-top: 3vh;
    margin-bottom: 2vh;
  }
  
  .signInForm {
    width: 88%;
  }
  
  .signInButton {
    margin-right: -1.5vw;
    width: 60vw;
    height: 7vh;
    font-size: 2.8vw;
    line-height: 2.8vw;
    margin-top: 1.5vh;
  }

  .accountContainer {
    width: 100%;
    height: calc(100% - 8vh);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10vh;
    margin-bottom: 3vh;
  }
  
  .accountSidebar {
    margin-top: 10vh;
    width: 80vw;
    align-self: center;
    padding-left: 6vw;
    padding-right: 6vw;
    margin-left: 0;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 0;
    border-bottom: 1px solid #131414;
    border-right: none;
  }
  
  .accountSidebarLink {
    height: auto;
    width: auto;
    font-size: 3vw;
    line-height: 4vw;
    margin-bottom: 2.5vh;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .mobileSidebarIcon {
    display: flex;
    width: 20px;
    height: 20px;
    color: #333;
  }
  
  .accountSidebarLinkDot {
    display: none;
  }
  
  .accountMainContainer {
    margin-top: 0;
    width: 88vw;
    padding-right: 6vw;
    padding-left: 6vw;
    margin-right: 0;
    margin-left: 0;
    height: 100%;
    display: block;
    overflow-y: auto;
  }

  .accountMainInfoContainer {
    width: 100%;
    padding-top: 3vh;
    padding-bottom: 10vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .accountMainInfoColumn {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .singleProductSelect {
    font-size: 5vw;
    line-height: 5vw;
    margin-top: 0;
    margin-bottom: 2vh;
    height: 11vw;
  }
  
  .singleProductSelectOption {
    font-size: 4vw;
    line-height: 4vw;
  }

  .orderDateHeaderColumn {
    margin-top: 3vh;
    font-size: 3.5vw;
    width: 20%;
  }
  
  .orderDateLineColumn {
    font-size: 3vw;
    width: 20%;
  }

  .orderIdHeaderColumn {
    width: 40%;
  }
  
  .orderPriceHeaderColumn {
    display: none;
  }
  
  .orderStatusHeaderColumn {
    width: 25%;
  }
  
  .orderPrintHeaderColumn {
    width: 5%;
  }
  
  .orderStatusContainer {
    width: 90%;
    height: auto;
    padding-top: 0.8vh;
    padding-bottom: 0.8vh;
    font-size: 2.5vw;
    border-radius: 5px;
  }

  .forgotPasswordButton {
    font-size: 3.5vw;
    margin-top: 3vh;
    margin-right: -0.7vw;
  }

  .loadingCollections {
    font-size: 3.5vw;
  }

  .accountPolicyText {
    width: 100%;
    margin-right: -1vw;
    font-size: 3.5vw;
    line-height: 5.5vw;
    color: #333;
    margin-top: 4vh;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {

  .collectionLineIcon {
    width: 15px;
    height: 15px;
  }
}