.whatsAppButtonContainer {
  position: fixed;
  top: 70vh;
  left: 0;
  margin-left: -5vw;
  z-index: 100001;
  width: 9vw;
  height: 4vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-radius: 20px;
  background-color: #131414;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.whatsAppButtonContainer:hover {
  opacity: 0.9;
}

.whatsAppButtonIcon {
  width: 1.5vw;
  height: 1.5vw;
  margin-right: 1vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .whatsAppButtonContainer {
    position: fixed;
    top: 75vh;
    left: 0;
    margin-left: -9vw;
    z-index: 100001;
    width: 18vw;
    height: 9vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-radius: 20px;
    background-color: #131414;
    cursor: pointer;
    transition: opacity 0.3s ease-out;
  }
  
  .whatsAppButtonContainer:hover {
    opacity: 0.9;
  }
  
  .whatsAppButtonIcon {
    width: 3vw;
    height: 3vw;
    margin-right: 2vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .whatsAppButtonContainer {
    position: fixed;
    top: 55vh;
    left: 0;
    margin-left: -12vw;
    z-index: 100001;
    width: 25vw;
    height: 14vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-radius: 20px;
    background-color: #131414;
    cursor: pointer;
    transition: opacity 0.3s ease-out;
  }
  
  .whatsAppButtonContainer:hover {
    opacity: 0.9;
  }
  
  .whatsAppButtonIcon {
    width: 6vw;
    height: 6vw;
    margin-right: 3vw;
  }
}