.productOverlayContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 100000000;
}

.productOverlayImgContainer {
  width: 50vw;
  display: block;
  overflow: hidden;
  z-index: 100000000;
  position: absolute;
  top: 0;
  left: 0;
}

.productOverlayImgContent {
  width: 100%;
  display: block;
  position: relative;
  overflow-y: auto;
}

.preOrderBannerSingleProduct {
  z-index: 10000000000;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding-top: 2vh;
  padding-bottom: 1.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.6vw;
  color: #fff;
  background-color: #131414;
}

.preOrderBannerSingleProductSmall {
  font-size: 0.9vw;
  margin-top: 0.8vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.productOverlayImgContent::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.productOverlayImgContent {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.productOverlayImgInner {
  height: auto;
  min-height: 100vh;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.productOverlayImg {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.productOverlayVideoContainer {
  width: 100%;
  height: auto;
  background: url('../assets/images/noPlay.jpg');
  background-size: cover;
}

.productOverlayVideo {
  width: 100%;
  min-height: 50vh;
  height: auto;
  object-fit: cover;
}

.productOverlayVideo:focus {
  outline: none;
}

.productOverlayInfoContainer {
  z-index: 10000000;
  width: 50vw;
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
}

.productOverlayInfoContent {
  width: 100%;
  display: block;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.productOverlayInfoContent::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.productOverlayInfoContent {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.productOverlayInfoInner {
  width: calc(100% - 7vw);
  padding-right: 4vw;
  padding-left: 3vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  background-color: #fff;
}

.productOverlayTitle {
  font-family: 'titleFont';
  font-size: 3vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 3vh;
}

.productOverlayDesc {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1vw;
  line-height: 2vw;
  text-align: right;
  width: 88%;
  color: #333;
  margin-top: 0;
  margin-bottom: 3vh;
}

.productOverlayPrice {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 2.2vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.productOverlayClose {
  z-index: 100000009;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  width: 4vw;
  height: auto;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.productOverlayClose:hover {
  transform: translate(-50%, 0) scale(1.1);
  -webkit-transform: translate(-50%, 0) scale(1.1);
  -moz-transform: translate(-50%, 0) scale(1.1);
}

.productOverlaySizesContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 4vh;
}

.productOverlaySizesTitle {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 1.3vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 2.5vh;
}

.productOverlaySizesButtons {
  width: auto;
  display: inline-block;
  height: auto;
  max-width: 80%;
}

.productOverlaySizesButton {
  display: inline-block;
  min-width: 1.5vw;
  min-height: 1.5vw;
  padding: 0.75vw;
  border-radius: 3vw;
  margin-left: 0.8vw;
  margin-bottom: 3vh;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 1.1vw;
  text-align: center;
  line-height: 1.5vw;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  border: 1px solid #e1e1e1;
  transition: all 0.5s ease-out;
  position: relative;
}

.productOverlayColorTooltip {
  width: auto;
  min-width: 7vw;
  height: auto;
  position: absolute;
  top: -1vh;
  right: 3vw;
  background-color: #333;
  padding: 0.15vh 0.6vw 0.15vw 0.6vw;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 0.8vw;
  color: #fff;
  border-radius: 4px;
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-out;
}

.productOverlaySizesButton:hover .productOverlayColorTooltip {
  opacity: 1;
}

.singleProductOverlayNoStock {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 0.8vw;
  line-height: 0.8vw;
  margin-top: -2vh;
  margin-bottom: 4vh;
  color: #333;
}

.buttonsContainer {
  margin-top: 2vh;
  margin-bottom: 4vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.keepShoppingButton {
  cursor: pointer;
  position: relative;
  width: 16vw;
  height: 7vh;
  background-color: #131414;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 0.8vw;
  line-height: 0.8vw;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1vw;
}

.keepShoppingButton:focus {
  outline: none;
}

.addToCartButton {
  border: 1px solid #131414; 
  cursor: pointer;
  position: relative;
  width: 16vw;
  height: 7vh;
  background-color: #fff;
}

.addToCartTopLayer {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  height: 7vh;
  background-color: #131414;
  z-index: 1000000009;
  overflow: hidden;
}

.addToCartTopLayerInner {
  position: absolute;
  top: 0;
  left: 50%;
  width: 16vw;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  height: 7vh;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 0.8vw;
  line-height: 0.8vw;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.addToCartBottomLayer {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 0.8vw;
  line-height: 0.8vw;
  color: #333;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1000000005;
}

.addToCartButton:focus {
  outline: none;
}

.addToCartButton:disabled {
  opacity: 0.6;
}

.extraInfoContainer {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  transition: filter 0.3s ease-out;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.extraInfoContainer:hover {
  filter: brightness(0.2);
}

.extraInfoIcon {
  width: 15px;
  height: 15px;
  color: #333;
  margin-right: 0.5vw;
}

.extraInfoText {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 0.9vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.mobileProductOverlayInfo, .mobileProductOverlayButtons {
  display: none;
}

.usefulInfoOverlayBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000000;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.usefulInfoOverlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  width: 40%;
  height: 90%;
  z-index: 1000000005;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.usefulInfoText {
  width: 90%;
  padding-right: 5%;
  padding-left: 5%;
  height: 90%;
  overflow-y: auto;
  display: block;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.usefulInfoText::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.usefulInfoText {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.usefulInfoTextInner {
  font-family: 'Helvetica';
  font-weight: 300;
  font-size: 0.9vw;
  line-height: 1.8vw;
  color: #333;
  margin-top: 4vh;
  margin-bottom: 4vh;
  text-align: justify;
}

.usefulInfoCloseButton {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Helvetica';
  font-weight: 700;
  font-size: 0.9vw;
  color: #fff;
  background-color: #131414;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.usefulInfoCloseButton:hover {
  opacity: 0.95;
}

.sizeTableInfoOverlay {
  width: 70%;
  height: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  z-index: 1000000005;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sizeTableImg {
  height: auto;
  width: 100%;
  object-fit: cover;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .productOverlayVideoContainer {
    background-position: 50% 0;
  }

  .productOverlayVideo {
    min-height: 40vh;
  }

  .productOverlayImgContainer {
    width: 100vw;
    display: block;
    overflow: hidden;
    z-index: 100000000;
    position: absolute;
    top: 0;
    left: 0;
  }

  .preOrderBannerSingleProduct {
    bottom: 5vh;
    width: 97%;
    padding-right: 3%;
    padding-bottom: 20vh;
    align-items: flex-end;
    font-size: 3vw;
    color: #000;
    background-color: rgba(166, 81, 63, 0);
  }
  
  .preOrderBannerSingleProductSmall {
    font-size: 2.2vw;
    margin-top: 0.5vh;
  }
  
  .productOverlayInfoContainer {
    display: none;
  }
  
  .mobileProductOverlayInfo {
    z-index: 100000009;
    display: block;
    width: 100vw;
    overflow-y: visible;
    background-color: #fff;
    position: absolute;
    left: 0;
  }

  .mobileProductOverlayButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
    position: absolute;
    top: -4.5vw;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    z-index: 100000009;
  }

  .mobileProductButtonsSeparator {
    width: 2vw;
  }

  .mobileProductButton {
    z-index: 100000009;
    width: 9vw;
    height: auto;
    cursor: pointer;
  }

  .productOverlayInfoContent {
    width: 100%;
    height: 100%;
    display: block;
    overflow-y: auto;
  }

  .productOverlayInfoInner {
    width: 88vw;
    padding-right: 6vw;
    padding-left: 6vw;
    padding-top: 7vh;
    padding-bottom: 7vh;
    height: auto;
    min-height: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .productOverlayTitle {
    font-size: 6vw;
    margin-bottom: 2vh;
  }
  
  .productOverlayDesc {
    font-size: 2vw;
    line-height: 4vw;
    text-align: left;
    width: 100%;
    margin-bottom: 3vh;
  }
  
  .productOverlayPrice {
    font-size: 3.5vw;
    margin-bottom: 5vh;
  }
  
  .productOverlayClose {
    display: none;
  }
  
  .productOverlaySizesContainer {
    align-items: flex-start;
    margin-bottom: 3vh;
  }
  
  .productOverlaySizesTitle {
    font-size: 2.1vw;
    margin-bottom: 2vh;
  }
  
  .productOverlaySizesButtons {
    max-width: 90%;
  }
  
  .productOverlaySizesButton {
    display: inline-block;
    min-width: 2vw;
    min-height: 2vw;
    padding: 1vw;
    border-radius: 3vw;
    margin-right: 1.2vw;
    margin-left: 0;
    margin-bottom: 2vh;
    font-size: 1.3vw;
    text-align: center;
    line-height: 2vw;
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
    border: 1px solid #e1e1e1;
    transition: all 0.5s ease-out;
    position: relative;
  }
  
  .productOverlayColorTooltip {
    display: none;
  }

  .buttonsContainer {
    margin-top: 2vh;
    margin-bottom: 3vh;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
  
  .keepShoppingButton {
    width: 25vw;
    height: 5vh;
    font-size: 1.4vw;
    line-height: 1.4vw;
    margin-right: 0;
    margin-left: 1.5vw;
  }
  
  .keepShoppingButton:focus {
    outline: none;
  }

  .addToCartButton {
    width: 25vw;
    height: 5vh;
  }

  .singleProductOverlayNoStock {
    font-size: 1.5vw;
    line-height: 1.5vw;
    margin-top: -1.5vh;
  }
  
  .addToCartTopLayer {
    height: 5vh;
  }
  
  .addToCartTopLayerInner {
    width: 25vw;
    height: 5vh;
    font-size: 1.4vw;
    line-height: 1.4vw;
  }
  
  .addToCartBottomLayer {
    font-size: 1.4vw;
    line-height: 1.4vw;
  }
  
  .extraInfoContainer:hover {
    filter: brightness(1);
  }
  
  .extraInfoIcon {
    width: 17px;
    height: 17px;
    margin-right: 1.5vw;
  }
  
  .extraInfoText {
    font-family: 'Helvetica Neue';
    font-weight: 300;
    font-size: 1.5vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .usefulInfoOverlay {
    width: 75%;
    height: 85%;
  }
  
  .usefulInfoText {
    width: 86%;
    padding-right: 7%;
    padding-left: 7%;
    height: 90%;
  }
  
  .usefulInfoTextInner {
    font-size: 1.8vw;
    line-height: 3.6vw;
  }
  
  .usefulInfoCloseButton {
    font-size: 1.8vw;
  }
  
  .usefulInfoCloseButton:hover {
    opacity: 1;
  }

  .sizeTableInfoOverlay {
    width: 95%;
    height: 53%;
  }
  
  .sizeTableImg {
    height: auto;
    width: 100%;
    object-fit: cover;
    margin-right: 0;
    margin-left: 0;
    margin-top: 3vh;
    margin-bottom: 0;
  }
}

@media (orientation: portrait) and (max-width: 767px) {

  .productOverlayImgInner {
    padding-bottom: 15vh;
  }

  .mobileProductOverlayButtons {
    top: -6vw;
  }

  .mobileProductButtonsSeparator {
    width: 3vw;
  }

  .mobileProductButton {
    width: 12vw;
  }

  .preOrderBannerSingleProduct {
    font-size: 4.5vw;
  }
  
  .preOrderBannerSingleProductSmall {
    font-size: 3.3vw;
  }

  .productOverlayInfoInner {
    width: 90vw;
    padding-right: 5vw;
    padding-left: 5vw;
    padding-top: 5vh;
    padding-bottom: 10vh;
  }
  
  .productOverlayTitle {
    font-size: 8vw;
    margin-bottom: 3vh;
  }
  
  .productOverlayDesc {
    font-size: 3.5vw;
    line-height: 7vw;
    margin-bottom: 3vh;
  }
  
  .productOverlayPrice {
    font-size: 5.5vw;
    margin-bottom: 4vh;
  }
  
  .productOverlaySizesContainer {
    align-items: flex-start;
    margin-bottom: 3.5vh;
  }
  
  .productOverlaySizesTitle {
    font-size: 4.2vw;
    margin-bottom: 1.5vh;
  }
  
  .productOverlaySizesButtons {
    max-width: 90%;
  }
  
  .productOverlaySizesButton {
    display: inline-block;
    min-width: 5vw;
    min-height: 5vw;
    padding: 3vw;
    border-radius: 10vw;
    margin-right: 2.5vw;
    margin-left: 0;
    margin-bottom: 2vh;
    font-size: 3.5vw;
    text-align: center;
    line-height: 5vw;
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
    border: 1px solid #e1e1e1;
    transition: all 0.5s ease-out;
    position: relative;
  }

  .buttonsContainer {
    width: 100%;
    margin-top: 1vh;
    margin-bottom: 3vh;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .keepShoppingButton {
    width: 60vw;
    height: 7vh;
    font-size: 3.2vw;
    line-height: 3.2vw;
    margin-right: 0;
    margin-left: 0;
    margin-top: 1.5vh;
  }
  
  .addToCartButton {
    width: 60vw;
    height: 7vh;
    align-self: flex-end;
  }

  .singleProductOverlayNoStock {
    font-size: 3.2vw;
    line-height: 3.2vw;
    margin-top: -1.5vh;
    align-self: flex-end;
  }
  
  .addToCartTopLayer {
    height: 7vh;
  }
  
  .addToCartTopLayerInner {
    width: 60vw;
    height: 7vh;
    font-size: 3.2vw;
    line-height: 3.2vw;
  }
  
  .addToCartBottomLayer {
    font-size: 3.2vw;
    line-height: 3.2vw;
  }

  .extraInfoContainer {
    align-self: flex-end;
  }
  
  .extraInfoIcon {
    width: 20px;
    height: 20px;
    margin-right: 3.5vw;
  }
  
  .extraInfoText {
    font-size: 3.5vw;
  }

  .usefulInfoOverlay {
    width: 90%;
    height: 95%;
  }
  
  .usefulInfoText {
    width: 86%;
    padding-right: 7%;
    padding-left: 7%;
    height: 88%;
  }
  
  .usefulInfoTextInner {
    font-size: 3.5vw;
    line-height: 7vw;
  }
  
  .usefulInfoCloseButton {
    height: 15%;
    font-size: 3.5vw;
    background-color: #131414;
  }

  .sizeTableInfoOverlay {
    width: 95%;
    height: 60%;
  }
  
  .sizeTableImg {
    height: auto;
    width: 100%;
    object-fit: cover;
    margin-right: 0;
    margin-left: 0;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
}